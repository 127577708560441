import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const HEADER_QUERY = graphql`
  {
    sanityStoreSettings {
      name
      logo {
        asset {
          gatsbyImageData(formats: AUTO)
        }
      }
    }
  }
`;

const HeaderStyle = styled.header`
  .top-links {
    display: flex;
    justify-content: flex-end;
    a,
    button {
      display: inline-block;
      padding: 16px 14px;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      letter-spacing: 0.03em;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
      transition: color 0.3s;
    }

    a:hover,
    button:hover {
      color: rgba(0, 0, 0, 0.9);
    }

    button {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  .logo {
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .gatsby-image-wrapper {
    max-width: 200px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 800px) {
    .non-mobile {
      display: none !important;
    }
  }
`;

export default function Header() {
  const { sanityStoreSettings } = useStaticQuery(HEADER_QUERY);

  const image = getImage(sanityStoreSettings.logo.asset);

  return (
    <HeaderStyle>
      <div className="top-links">
        <Link className="non-mobile" to="/about">
          About Us
        </Link>
        <a className="non-mobile" href="mailto:hello@thelittlelighthouse.co.uk">
          Contact Us
        </a>
        <button type="button" className="snipcart-customer-signin">
          Log In
        </button>
        <button type="button" className="snipcart-checkout">
          View Basket ( <span className="snipcart-items-count" /> )
        </button>
      </div>

      <div className="logo">
        <Link to="/">
          <GatsbyImage image={image} alt={sanityStoreSettings.name} />
        </Link>
      </div>
    </HeaderStyle>
  );
}
