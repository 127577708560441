import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  cursor: pointer;
  animation-name: animateIn;
  animation-duration: 0.3s;

  @keyframes animateIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export default function Backdrop({ click }) {
  return <BackdropStyled onClick={click} />;
}

Backdrop.propTypes = {
  click: PropTypes.func.isRequired,
};
