import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BurgerButtonStyled = styled.button`
  border: 0;
  display: none;
  margin: 1rem auto;
  color: none;
  background-color: transparent;
  cursor: pointer;
  pointer-events: none;

  &:focus,
  &:active,
  &:hover,
  &:visited {
    color: transparent;
  }

  div {
    width: 35px;
    height: 5px;
    background-color: var(--heading-color);
    margin: 6px;
    border-radius: 6px;
  }

  @media only screen and (max-width: 800px) {
    display: block;
    pointer-events: all;
  }
`;

export default function BurgerButton({ click }) {
  return (
    <BurgerButtonStyled className="burger-button" type="button" onClick={click}>
      <div />
      <div />
      <div />
    </BurgerButtonStyled>
  );
}

BurgerButton.propTypes = {
  click: PropTypes.func.isRequired,
};
