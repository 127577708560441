import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import BurgerButton from './BurgerButton';
import NavSideDrawer from './NavSideDrawer';
import Backdrop from './Backdrop';

const NAVIGATION_QUERY = graphql`
  {
    settings: sanityStoreSettings {
      menu {
        id
        title
        slug {
          current
        }
      }
    }
  }
`;

const NavWrapper = styled.div`
  width: 100vw;
  text-align: center;
  border-bottom: 7px solid lightgray;

  .desktop_nav a {
    display: inline-block;
    padding: 16px 14px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    letter-spacing: 0.03em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
  }

  @media only screen and (max-width: 800px) {
    .desktop_nav {
      display: none;
    }
  }
`;

export default function Navigation() {
  const { settings } = useStaticQuery(NAVIGATION_QUERY);

  const [isSidebarOpen, setisSidebarOpen] = useState(false);

  // opens and closes sidebar depending on current state
  const handleSidebarToggle = () => setisSidebarOpen(!isSidebarOpen);

  return (
    <NavWrapper>
      <nav className="desktop_nav">
        <Link to="/">Home</Link>

        {settings?.menu?.map((item) => (
          <Link key={item.id} to={`/${item.slug.current}`}>
            {item.title}
          </Link>
        ))}
      </nav>

      <BurgerButton click={handleSidebarToggle} />

      <NavSideDrawer
        menu={settings.menu}
        click={handleSidebarToggle}
        shown={isSidebarOpen}
      />

      {isSidebarOpen ? <Backdrop click={handleSidebarToggle} /> : null}
    </NavWrapper>
  );
}
