import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Inter:400|Playfair+Display:400');

    html {font-size: 100%;} /*16px*/

    body {
    background: white;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 1.75;
    color: var(--body-color);
    }

    p {
        margin-bottom: 1rem;
        line-height: 180%;
    }

    h1, h2, h3, h4, h5 {
    margin: 3rem 0 1.38rem;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    line-height: 1.3;
    color: var(--heading-color);
    }

    h1 {
    margin-top: 0;
    font-size: 3.052rem;
    }

    h2 {font-size: 2.441rem;}

    h3 {font-size: 1.953rem;}

    h4 {font-size: 1.563rem;}

    h5 {font-size: 1.25rem;}

    small, .text_small {font-size: 0.8rem;}
`;

export default Typography;
