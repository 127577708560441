import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
:root {
    --heading-color: #272343;
    --body-color: #2D334A;

    --white: #FFFFF2;
    --gray: #ECF0F1;
    --grey: var(--gray);
    --yellow: #FFD803;
    --red: #FD6244;

    --secondary: #E3F6F5;
    --tertiary: #BAE8E8;
    --highlight: var(--yellow);

    --mediaQuery: 800px;
}
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
    }

    .container {
        width: 95%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .containerSmaller { 
        width: 95%;
        max-width: 960px;
        margin: 0 auto;
    }

    

     /* unvisited link */
    a:link {
        color: inherit;
        transition: .2s;
        text-decoration: none;
    }

    /* visited link */
    a:visited {
        color: inherit;
    }

    /* mouse over link */
    a:hover {
        color: var(--yellow);
    }

    /* selected link */
    a:active {
        color: blue;
    }
`;

export default GlobalStyle;
