import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FOOTER_QUERY = graphql`
  {
    settings: sanityStoreSettings {
      name
    }
  }
`;

const FooterStyle = styled.footer`
  width: 100vw;
  padding: 60px 0;
  background: var(--grey);
  text-align: center;
`;

export default function Footer() {
  const { settings } = useStaticQuery(FOOTER_QUERY);

  return (
    <FooterStyle>
      <p>{`${settings.name} © ${new Date().getFullYear()}`}</p>
    </FooterStyle>
  );
}
