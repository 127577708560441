import React from 'react';
import PropTypes from 'prop-types';

import 'normalize.css';
import Typography from '../../styles/Typography';
import GlobalStyle from '../../styles/GlobalStyles';
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import Navigation from './Navigation';

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <Typography />
      <Header />
      <Navigation />
      <main>{children}</main>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};
