import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const SidebarStyled = styled.div`
  height: 100vh;
  width: 75%;
  max-width: 500px;
  background-color: var(--gray);
  position: fixed;
  top: 0;
  left: -800px;
  z-index: 100;
  opacity: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    left: 0px;
    opacity: 1;
  }

  nav {
    a {
      display: block;
      padding: 16px 0;
      text-align: center;
    }
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 35px;
    color: var(--red);
    margin: 14px;
    cursor: pointer;
  }
`;

export default function NavSideDrawer({ menu, click, shown }) {
  const classes = shown ? 'open' : '';

  return (
    <SidebarStyled className={classes}>
      <span className="close-button" onClick={click}>
        <AiOutlineCloseCircle />
      </span>

      <nav>
        <Link onClick={click} to="/">
          Home
        </Link>
        {menu.map((item) => (
          <Link onClick={click} key={item.id} to={`/${item.slug.current}`}>
            {item.title}
          </Link>
        ))}

        <hr />

        <Link onClick={click} to="/about">
          About Us
        </Link>

        <a className="non-mobile" href="mailto:hello@thelittlelighthouse.co.uk">
          Contact Us
        </a>
      </nav>
    </SidebarStyled>
  );
}

NavSideDrawer.propTypes = {
  menu: PropTypes.array.isRequired,
  click: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
};
