import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

const NAV_QUERY = graphql`
  {
    settings: sanityStoreSettings {
      menu {
        id
        title
        slug {
          current
        }
      }
    }
  }
`;

const NavStyle = styled.nav`
  width: 100vw;
  text-align: center;
  border-bottom: 8px solid lightgray;

  a {
    display: inline-block;
    padding: 16px 14px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    letter-spacing: 0.03em;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.8);
  }
`;

export default function Nav() {
  const { settings } = useStaticQuery(NAV_QUERY);

  return (
    <NavStyle>
      <Link to="/">Home</Link>
      {settings.menu.map((item) => (
        <Link key={item.id} to={`/${item.slug.current}`}>
          {item.title}
        </Link>
      ))}
    </NavStyle>
  );
}
